// Packages
import styled from 'styled-components'

export const StyledOurClients = styled.section `
  background-color: #fff;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledWrapper = styled.div`
  padding: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 85%;
  }
`

export const StyledCircles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`

export const StyledCircleTitleContainer = styled.div`
  height: 100%;    
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
`

export const StyledCircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledCircle = styled.div`
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 2px 2px #00000029;
  height: 5rem;
  text-align: center;
  display: block;
  width: 5rem;

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    height: 6rem;
    width: 6rem;
  }
`

export const StyledIconUser = styled.img`
  height: 100%;
  width: 100%;
`

export const StyledCircleTitleWrapper = styled.div`
  height: 100%;
`

export const StyledCircleTitle = styled.p`
  align-items: flex-start;
  display: flex;
  height: 100%;
  line-height: 1.3;
  margin-top: .5rem;
  text-align: center;
  width: 100%;
`

export const StyledTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;  
`
