// Packages
import React from 'react'

// Styled components
import { StyledOurClients, StyledWrapper, StyledCircles, StyledCircle, StyledCircleTitle, StyledCircleTitleWrapper, StyledCircleWrapper, StyledCircleTitleContainer, StyledIconUser, StyledTitle } from './styledComponents'

// Assets
import Client1 from '../../../data/assets/img/our-clients/user-1.jpg'
import Client2 from '../../../data/assets/img/our-clients/user-2.jpg'
import Client3 from '../../../data/assets/img/our-clients/user-3.jpg'
import Client4 from '../../../data/assets/img/our-clients/user-4.jpg'

const OurClients = props => {
  return (
    <StyledOurClients {...props}>
      <StyledWrapper>
        <StyledTitle>Nuestros clientes nos recomiendan</StyledTitle>
        <StyledCircles>
          <StyledCircleTitleContainer>
            <StyledCircleWrapper>
              <StyledCircle>
                <StyledIconUser src={Client1} />
              </StyledCircle>
            </StyledCircleWrapper>
            <StyledCircleTitleWrapper>
              <StyledCircleTitle>Me dieron seguridad y confianza</StyledCircleTitle>
            </StyledCircleTitleWrapper>
          </StyledCircleTitleContainer>
          <StyledCircleTitleContainer>
            <StyledCircleWrapper>
              <StyledCircle>
                <StyledIconUser src={Client2} />
              </StyledCircle>
            </StyledCircleWrapper>
            <StyledCircleTitleWrapper>
              <StyledCircleTitle>Precios accesibles</StyledCircleTitle>
            </StyledCircleTitleWrapper>
          </StyledCircleTitleContainer>
          <StyledCircleTitleContainer>
            <StyledCircleWrapper>
              <StyledCircle>
                <StyledIconUser src={Client3} />
              </StyledCircle>
            </StyledCircleWrapper>
            <StyledCircleTitleWrapper>
              <StyledCircleTitle>Rápido y sencillo</StyledCircleTitle>
            </StyledCircleTitleWrapper>
          </StyledCircleTitleContainer>
          <StyledCircleTitleContainer>
            <StyledCircleWrapper>
              <StyledCircle>
                <StyledIconUser src={Client4} />
              </StyledCircle>
            </StyledCircleWrapper>
            <StyledCircleTitleWrapper>
              <StyledCircleTitle>Excelente atención, otra opción para hacer análisis.</StyledCircleTitle>
            </StyledCircleTitleWrapper>
          </StyledCircleTitleContainer>          
        </StyledCircles>
      </StyledWrapper>
    </StyledOurClients>
  )
}

export default OurClients
